/**
 * original founction library
 * [usage]
 * import moment from '@/assets/lib/moment'
 * mixins: [moment]
 */
import moment from 'moment'
import Vue from 'vue'

moment.locale('ja', {
  weekdays: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日']
})

export default Vue.extend({
  computed: {
    /**
     * @param {String} date 検証したい日付（形式：YYYYMMDD）
     * @return {Boolean} 存在する日付かどうか
     */
    existDate () {
      return date => moment(date).isValid()
    }
  },
  methods: {
    /**
     * @param {Date} date フォーマット変換したい日付
     * @param {String} style フォーマット後のスタイル YYYY-MM-DDなど
     * @return {String} フォーマット後の日付
     */
    formated (date, style) {
      return moment(date).format(style)
    }
  }
})
