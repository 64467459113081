<template>
  <div class="maintenance-detail">
    <h2 class="maintenance-detail__title">メンテナンス開始日</h2>
    <div class="maintenance-detail__content">{{ formated(maintenance.createdAt.toDate(), 'YYYY/MM/DD') }}</div>
    <h2 class="maintenance-detail__title">メンテナンス理由</h2>
    <div class="maintenance-detail__content">{{ maintenance.memo }}</div>
  </div>
</template>

<script>
import moment from '@/assets/lib/moment'
export default {
  mixins: [moment],
  props: {
    // メンテナンス情報
    maintenance: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.maintenance-detail {
  &__title {
    margin-bottom: 5px;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
  &__content {
    margin-bottom: 20px;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
}
</style>
