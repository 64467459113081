<template>
  <div class="maintenance-end" v-if="!isProcessing">
    <div v-if="isMaintenance">
      <maintenance-detail :maintenance="maintenance" />
      <v-btn class="maintenance-end__btn" depressed @click="open()">メンテナンスの終了</v-btn>
      <confirm-dialog ref="confirm" title="メンテナンスの終了" msg="メンテナンスを終了すると、ユーザーがアプリにアクセスできるようになります。Firestore Rulesのアクセス権限は制限されているので、Rulesをデプロイ後「はい」を押下する必要があります。 本当によろしいですか？" @do-action="endMaintenance()" />
    </div>
    <div v-else>
      <div class="maintenance-end__warning">現在メンテナンス中ではありません。</div>
    </div>
  </div>
</template>

<script>
import ConfirmDialog from '@/components/common/confirm'
import MaintenanceDetail from '@/components/maintenance/detail.vue'

export default {
  name: 'maintenance_end',
  components: { ConfirmDialog, MaintenanceDetail },
  data () {
    return {
      // メンテナンス理由
      memo: ''
    }
  },
  async mounted () {
    // 常に最新を取得
    await this.$store.dispatch('maintenances/getMaintenance')

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Object} メンテナンス情報
     */
    maintenance () {
      return this.$store.getters['maintenances/maintenance']
    },
    /**
     * @return {Boolean} メンテナンス中かどうか
     */
    isMaintenance () {
      return this.maintenance && !this.maintenance.endAt
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  },
  methods: {
    /**
     * ダイアログを開く
     */
    open () {
      this.$refs.confirm.open()
    },
    /**
     * メンテナンスを終了する
     */
    async endMaintenance () {
      this.$store.commit('setSubmitting', true)

      const payload = {
        mid: this.maintenance.mid,
        params: {
          endAt: new Date()
        }
      }
      // メンテナンスモードを停止
      await this.$store.dispatch('maintenances/updateMaintenance', payload)
      // Stripeの定期支払い回収を再開
      await this.$store.dispatch('functions/switchSubscriptionCollections', false)
      // 定期支払い回収停止中に発生したインボイスを再起動
      await this.$store.dispatch('functions/restartInvoices')

      // ホームへ遷移
      this.$store.commit('setTelop', { show: true, msg: 'メンテナンスを終了しました', type: 'success' })
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.maintenance-end {
  width: 100vw;
  max-width: $max_width;
  padding: $padding_height $padding_width;
  margin: $header_height auto 0 auto;
  &__btn {
    display: block;
    margin: 40px auto 0;
    font-size: 1.2rem;
    color: $white_color;
    border-radius: 10px;
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: auto;
      height: auto;
      padding: 10px 15px;
    }
    &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $orange_color;
    }
  }
  &__warning {
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: $red_color;
    text-align: center;
  }
}
</style>
